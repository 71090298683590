import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            color: "#000",
            fontSize: "1.4888rem",
            background: "#fff",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "15px",
            fontFamily: "Inter, sans-serif",
            borderRadius: "5px",
          },
          success: {
            icon: null,
          },
          error: {
            icon: null,
          },
          duration: 5000,
        }}
      />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
