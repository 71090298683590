import React from "react";
import styles from "./Service.module.css";
import basement from "../../assets/Services/basement2.jpg";
import { Link } from "react-router-dom";
const Service = () => {
  return (
    // <div className={styles["cards"]}>
    //   <Fade left>
    //     <div className={styles["card"]}>
    //       <figure>
    //         <div className={styles["overlay"]}>
    //           <h2>Legal Basement</h2>
    //         </div>
    //         <img src={basement} alt="" />
    //       </figure>
    //     </div>
    //   </Fade>
    //   <Fade right>
    //     <div className={styles["card-content"]}>
    //       <h2>Legal basement</h2>
    //       <p>
    //         Second and third units are permitted in detached, semi-detached and
    //         townhouse dwellings, subject to zoning requirements and
    //         restrictions. A second/third unit is a self-contained residential
    //         unit located within a principal dwelling. The unit may be in any
    //         part of the dwelling, including the basement. Additional residential
    //         units provide an affordable housing option.
    //       </p>
    //       <Link className="btn">Legal basement</Link>
    //     </div>
    //   </Fade>
    // </div>
    <div className={styles["cards"]}>
      <div className={styles["card"]}>
        <div className={styles["lines"]}></div>
        {/* <div className={styles["imgBx"]}></div> */}
        <div className={styles["content"]}>
          <div className={styles["details"]}>
            <figure>
              <img src={basement} alt="" />
            </figure>
            {/* <div className={styles["overlay"]}>
                  <h2>Legal Basement</h2>
                </div> */}
            {/* </div> */}

            <div className={styles["card-content"]}>
              <h2>Legal basement</h2>
              <p>
                Second and third units are permitted in detached, semi-detached
                and townhouse dwellings, subject to zoning requirements and
                restrictions. A second/third unit is a self-contained
                residential unit located within a principal dwelling. The unit
                may be in any part of the dwelling, including the basement.
                Additional residential units provide an
                affordable housing option.
              </p>
              {/* <Link className="btn">Legal basement</Link> */}
            </div>

            {/* <div className={styles["left"]}>
              <img src={basement} alt="" />
            </div>
            <div className={styles["right"]}>
              <h2>Legal basement</h2>
              <p>
                Second and third units are permitted in detached, semi-detached
                and townhouse dwellings, subject to zoning requirements and
                restrictions. A second/third unit is a self-contained
                residential unit located within a principal dwelling. The unit
                may be in any part of the dwelling, including the basement.
                Additional residential units provide an
                affordable housing option.
              </p>
              <Link className="btn">Legal basement</Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
