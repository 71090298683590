import React, { useState } from "react";
import "./ContactPage.css";
import Fade from "react-reveal/Fade";
import img from "../../assets/Images/landingImage.jpg";
import { Link } from "react-router-dom";

const ContactPage = () => {
  const [isClose, setIsClose] = useState(false);
  return (
    <>
      <div className="contact" id="contact">
        <h1>Contact Us</h1>
        <div className={isClose ? "popup" : "popup close"}>
          <a href="tel:+14169048486">Call Us +1 416-904-8486</a>
          <a href="tel:+16043680751">Call Us +1 604-368-0751</a>

          <Link to="mailto:DecentHomesInc@gmail.com">Email Us</Link>
          <span onClick={() => setIsClose(false)}>x</span>
        </div>
        <div className="content">
          <Fade right>
            <div className="left">
              <img src={img} alt="" />
            </div>
          </Fade>
          <Fade left>
            <div className="right">
              <div className="box">
                <h2>Davinder Singh</h2>
                <span>
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                      />
                    </svg>
                  </div>
                  Cell: 416-904-8486
                </span>
              </div>
              <div className="box">
                <h2>Gurdarsh Grewal</h2>
                <span>
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                      />
                    </svg>
                  </div>
                  Cell: 604-368-0751
                </span>
              </div>

              <div className="box">
                <h2>Gmail Address</h2>
                <span>
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                      />
                    </svg>
                  </div>
                  DecentHomesInc@gmail.com
                </span>
              </div>
              <Link className="btn" onClick={() => setIsClose(true)}>
                Get Free Quote
              </Link>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
