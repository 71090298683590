import React from "react";
import "./AboutPage.css";
import about from "../../assets/Images/aboutImg.jpg";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
const AboutPage = () => {
  return (
    <>
      <div className="about" id="about">
        <h1>About Us</h1>
        <div className="about-us">
          <Fade left>
            <div className="right-side">
              <img src={about} alt="" />
            </div>
          </Fade>
          <Fade right>
            <div className="left-side">
              <p>
                We are your trusted experts in legal basement transformations.
                Specializing in legal basement construction, our comprehensive
                services include general contracting, securing building permits,
                and crafting architectural drawings. With a keen understanding
                of regulations and a commitment to excellence, we seamlessly
                convert basements into functional, compliant, and beautifully
                designed spaces. From concept to completion, we bring your
                vision to life while ensuring full compliance with local codes
                and standards. Elevate your home with our expertise, where
                precision and creativity converge to deliver exceptional legal
                basement solutions.
              </p>
              <div className="social-links">
                <span>Follow Us:</span>
                <Link to="https://instagram.com/decenthomesinc_?igshid=NzZlODBkYWE4Ng==">
                  <FontAwesomeIcon icon={faInstagram} className="icon" />
                </Link>
                <Link to="https://www.tiktok.com/@ggrewal013?_t=8fnHTiAdMbF&_r=1">
                  <FontAwesomeIcon icon={faTiktok} className="icon" />
                </Link>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
