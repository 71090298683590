import { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import { Link as ScrollLink } from "react-scroll";

const Header = () => {
  const [toggleDropDown, setToggleDropDown] = useState(false);

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  return (
    <nav className={color ? "navbar navbar-bg" : "navbar"}>
      <ScrollLink to="home" className="logo">
        <div className={color ? "icon color" : "icon"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>
        </div>
        <p className={color ? "color" : ""}>Decent Homes</p>
      </ScrollLink>

      {/* Desktop Navigation */}
      <div className="desktop-nav">
        <ul className="nav-links">
          <li className={color ? "color" : ""}>
            <ScrollLink className={color ? "color" : ""} to="home">
              Home
            </ScrollLink>
          </li>
          <li className={color ? "color" : ""}>
            <ScrollLink className={color ? "color" : ""} to="gallary">
              Gallery
            </ScrollLink>
          </li>
          <li className={color ? "color" : ""}>
            <ScrollLink className={color ? "color" : ""} to="about">
              About
            </ScrollLink>
          </li>
          <li className={color ? "color" : ""}>
            <ScrollLink className={color ? "color" : ""} to="service">
              Service
            </ScrollLink>
          </li>
          <li className={color ? "color" : ""}>
            <ScrollLink className={color ? "color" : ""} to="contact">
              Contact Us
            </ScrollLink>
          </li>
        </ul>
      </div>

      {/* Mobile Navigation */}
      <div className="mobile-nav">
        <div className="nav-toggle">
          <div
            className={color ? "hamb color" : "hamb"}
            onClick={() => setToggleDropDown((prev) => !prev)}
          >
            {/* hello */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </div>

          {toggleDropDown && (
            <div className="dropdown">
              <ScrollLink to="home" onClick={() => setToggleDropDown(false)}>
                Home
              </ScrollLink>
              <ScrollLink to="gallary" onClick={() => setToggleDropDown(false)}>
                Gallery
              </ScrollLink>
              <ScrollLink to="about" onClick={() => setToggleDropDown(false)}>
                About
              </ScrollLink>
              <ScrollLink to="service" onClick={() => setToggleDropDown(false)}>
                Service
              </ScrollLink>
              <ScrollLink to="contact" onClick={() => setToggleDropDown(false)}>
                Contact Us
              </ScrollLink>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
