import architect from "../../assets/Services/architectualDrawings.jpg";
import bathroomR from "../../assets/Services/bathroomR.jpg";
import flooring from "../../assets/Services/flooring.jpg";
import paint from "../../assets/Services/paint.jpg";
export const ServiceData = [
  {
    img: architect,
    btn: "Architectural Drawings",
  },
  {
    img: bathroomR,
    btn: "Bathroom Remodeling",
  },
  {
    img: flooring,
    btn: "Flooring",
  },
  {
    img: paint,
    btn: "Paint",
  },
];
