import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./Pages/Home/HomePage";
import Header from "./Components/Header/Header";
import AboutPage from "./Pages/About/AboutPage";
import ServicePage from "./Pages/Services/ServicePage";
import "./App.css";
import ContactPage from "./Pages/Contact/ContactPage";
import FooterPage from "./Pages/Footer/FooterPage";
import ImageGallary from "./Pages/Gallary/ImageGallary";
function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <HomePage />
              <ImageGallary />
              <AboutPage />
              <ServicePage />
              {/* <ServicePage /> */}
              <ContactPage />
              <FooterPage />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
