import React, { useState } from "react";
import styles from "./FooterPage.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
const FooterPage = () => {
  return (
    <>
      <footer>
        <div className={styles["footer-content"]}>
          <div className={styles["footer-content-info"]}>
            <h3>Decent Homes</h3>
            <p>
              At our core, we are driven by the desire to redefine spaces,
              bridging the gap between architecture and aesthetics. Experience
              the difference in our customized glass solutions, where innovation
              and craftsmanship merge to shape a future of unparalleled beauty.
            </p>
            <div className={styles["socials"]}>
              <Link
                to="https://www.tiktok.com/@ggrewal013?_t=8fnHTiAdMbF&_r=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTiktok} className={styles["icon"]} />
              </Link>
              <Link
                to="https://instagram.com/decenthomesinc_?igshid=NzZlODBkYWE4Ng=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className={styles["icon"]}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className={styles["footer-bottom"]}>
          <p>Copyright&copy;2023 Decent Homes. </p>
          <Link
            to="https://www.linkedin.com/in/davinder-kumar-570814215/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Developed by Davinder-K
          </Link>
        </div>
      </footer>
    </>
  );
};

export default FooterPage;
