import img1 from "../../assets/Gallary/img1.jpg";
import img2 from "../../assets/Gallary/img2.jpg";
import img3 from "../../assets/Gallary/img3.jpg";
import img4 from "../../assets/Gallary/img4.jpg";
import img5 from "../../assets/Gallary/img5.jpg";
import img6 from "../../assets/Gallary/img6.jpg";
export const gallaryData = [
  {
    img: img6,
  },
  {
    img: img5,
  },
  {
    img: img3,
  },
  {
    img: img4,
  },
  {
    img: img1,
  },
  {
    img: img2,
  },
];
