import React from "react";
import Fade from "react-reveal/Fade";
import img from "../../assets/Images/landingImage.jpg";

import "./HomePage.css";
import Navbar from "../../Components/Header/Header";
import { Link } from "react-scroll";
const HomePage = () => {
  return (
    <section id="home">
      <Navbar />
      <div className="image">
        <img src={img} alt="" />
      </div>
      <div className="home-data">
        <Fade duration={2000} delay={100} bottom>
          <h1 className="Home-head_text">Crafting Dreams with Decent Homes</h1>
        </Fade>
        <Fade duration={2000} delay={100} bottom>
          <p className="desc ">
            Elevate your space with Decent Homes. Expert renovation, innovative
            design. Your vision, our passion. Crafting dream homes with style
            and excellence.
          </p>
        </Fade>
        <div className="relative">
          <span></span>
        </div>
        <Fade duration={2000} delay={100} bottom>
          <button className="outline_btn">
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                />
              </svg>
            </div>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={50}
              duration={200}
            >
              Get in Touch
            </Link>
          </button>
        </Fade>
      </div>
    </section>
  );
};

export default HomePage;
